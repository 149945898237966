var myMixin = {
    data(){
        return{
            layout: 'inline',//搜索框类型
            labelcol:{//搜索框尺寸
                md:{span: 8},
                sm:{span: 8}
            },
            wrappercol:{
                md:{span: 16},
                sm:{span: 16}
            },
        }
    },
    created: function () {
      
    },
    methods: {
     
    }
  }

  export{myMixin}